import React from 'react';
import Styled from 'styled-components';


import AboutMe from './AboutMe';



const Wrapper = Styled.div`



padding-top: 200px;
  




`;

const About = () => {
  return (
    <Wrapper>
      <div>
        

    <AboutMe />



      </div>
    </Wrapper>
  );
};

export default About;

